import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import "../styles/basic-page.scss";

const SizingPage = ({ data }) => {
  return (
    <Layout pageTitle="Size guide">
      {data.sanitySizing._rawContent !== null && (
        <div className="text">
          <BlockContent
            blocks={data.sanitySizing._rawContent}
            imageOptions={{ w: 800 }}
          />
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  {
    sanitySizing(_id: { eq: "single-sizing" }) {
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

export default SizingPage;
